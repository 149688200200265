/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import logo from 'assets/img/logo.svg';
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import {TOOLS_INDEX} from "tools/ToolsIndex.js";
import CardInfo from "components/Cards/CardInfo.js";

import FooterSmall from "components/Footers/FooterSmall.js";
import GoogleAds from "components/GoogleAds.js";

export default function ToolsView(props) {

  return (
    <>
        <IndexNavbar   />

        <div className="grid  grid-cols-12 gap-3 w-full">
            <div id="ads-left" className="col-span-3 border shadow ml-2">
              <GoogleAds style={{display:"block"}} clientid="ca-pub-1930528670531088" slotid= "9451547011" responsive={true}/>
            </div>
            <div id="content" className="col-span-6">
                {props.tool}
            </div>
            <div id="ads-right" className="col-span-3 border shadow mr-2">
                <GoogleAds style={{display:"block"}} clientid="ca-pub-1930528670531088" slotid= "3395073429" responsive={true}/>
            </div>
          </div>
          <div id="ad-bottom" className=" w-full lg:mb-5 h-100-px border shadow px-3 py-3 mt-3 ml-2 mr-2">
              <GoogleAds style={{}} clientid="ca-pub-1930528670531088" slotid= "5298062479" responsive={true}/>
          </div>
          <div className="mb-100-px w-full h-100-px"></div>
       <FooterSmall />

    </>
  );
}
