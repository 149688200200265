import React from "react";
import { Link } from "react-router-dom";
import {TOOLS_INDEX} from "tools/ToolsIndex.js";
import Tooltip from "components/Tooltip.js"

export default function NavTabs(props) {
return (<>
<ul className="flex flex-col lg:flex-row list-none">
            {Object.entries(TOOLS_INDEX).map(
             ([key, val]) =>{
               if(!val.enabled) return null;
               return(

               <li  data-tooltip-target={"tooltip"+key} key={key} className="flex items-center navitem cursor-pointer hover:-nv-item ml-3 mr-3 py-3">
              <Link
              to={val.route}
              className="text-blueGray-500 leading-none text-xs block  whitespace-nowrap uppercase bg-blueGray-50"
              >
              <Tooltip key={val.description} message = {val.description}>
              {val.name}
              </Tooltip>
            </Link>
              </li>
              )
             })
            }

              </ul>
              </>);
}
