import React, { Component  } from 'react'
import { GoogleLogin} from 'react-google-login'

class SignInWithGoogle extends Component{

  render () {
        const client_id = "673483176242-ll77san8v8vek1fep559rscn23dj2tg4.apps.googleusercontent.com"
        const handleLogin = async googleData => {
          console.log(googleData)
          const res = await fetch("http://localhost:8080/api/v1/auth/google", {
              method: "POST",
              body: JSON.stringify({
              token: googleData.tokenId
            }),
            headers: {
              "Content-Type": "application/json"
            }
          })
          const data = await res.json()
          // store returned user somehow
        }

        return <GoogleLogin
    clientId= {client_id}
    buttonText="Log in with Google"
    onSuccess={handleLogin}
    onFailure={handleLogin}
    cookiePolicy={'single_host_origin'}
/>
    }
}

export default SignInWithGoogle;
