import React from "react";

// components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

// views

import EditLayout from "views/Edit.js";

export default function Auth() {
  return (
    <>
      <IndexNavbar fixed />
      <main>
        <section className="relative w-full py-40 grid place-items-center">

           <div className="border-0 px-3 py-3  bg-white rounded  w-8/12">
               <EditLayout/>
          </div>


        </section>
        <Footer relative/>
      </main>
    </>)
}
