import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import Editor from "layouts/Editor.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import ToolsView from "views/ToolsView.js";
import Privacy from "views/Privacy.js";

//tools
import JsonFormatter from "tools/views/JsonFormatter.js";
import StrataEditor from "components/Editor/StrataEditor.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route exact path="/json" render={(props) => <ToolsView tool={<JsonFormatter/>} {...props} /> } />
      <Route path="/quicknote" render={(props) => <ToolsView tall tool={<StrataEditor tall />} {...props} /> }  />
      <Route path="/convert" exact component={Editor} />
      {/* add routes without layouts */}
      <Route path="/base64" exact component={Landing} />
      <Route path="/url_encode_decode" exact component={Profile} />
      <Route path="/privacy"  exact component={Privacy}/>
      <Redirect from="/" to="/json"/>
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
