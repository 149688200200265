/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import NavTabs from "./NavTabs.js"

export default function Navbar({ id}) {
  return (
    <>
      <nav id = {id} className= "relative  z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white shadow" >

        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <NavTabs/>
        </div>
      </nav>
    </>
  );
}
