export const DEFAULT_CONTENT =  [
    {
              id: "sheNwCUP5A",
              type: "header",
              data: {
                text: "Sample document",
                level: 1
              }
            },
             {
              id: "hcdbhdjkd",
              type: "paragraph",
              data: {
                text:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
              }
            },
              {
              id: "poiuhgdns",
              type: "header",
              data: {
                text: "This is a sub heading ",
                level: 2
              }
            },

                         {
              id: "hcdbhdjkd",
              type: "paragraph",
              data: {
                text:
                  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage,"
              }
            },

            {
              id: "hdehu39u",
              type: "quote",
              data: {
                text:
                  "\"Knowledge is power.\"",
                caption:
                  "-Francis Bacon"

              }
            },


];
