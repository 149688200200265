import React, { Component  } from 'react'

class GoogleAds extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
       let params=this.props;
    return(
        <div>
     <ins className="adsbygoogle"
     style={params.style}
     data-ad-client={params.clientid}
     data-ad-slot={params.slotid}
     data-full-width-responsive={params.responsive}></ins>
        </div>)
    }
}

export default GoogleAds
