/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import PrivacyPolicy from 'components/PrivacyPolicy.js';
import IndexNavbar from "components/Navbars/IndexNavbar.js";


export default function Privacy() {

  return (
    <>
      <IndexNavbar   />
        <div className="grid  grid-cols-12 gap-3 w-full h-650-px">
            <div id="ads-left" className="col-span-2">
            </div>
            <div id="content" className="col-span-8">
            <PrivacyPolicy />
            </div>
            <div id="ads-right" className="col-span-2">
            </div>
        </div>
    </>
  );
}
