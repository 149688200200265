import React, {  useRef, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./Constants";
import {DEFAULT_CONTENT} from "./DefaultContent";

const ReactEditorJS = createReactEditorJS();

export default function  StrataEditor(props) {
 // eslint-disable-next-line
  const headingsContainerRef = useRef();

  const [currentDocContent] = useState(DEFAULT_CONTENT);
   // eslint-disable-next-line
	const [pageHeadingNodes, setPageHeadingNodes] = useState([]);
   // eslint-disable-next-line
	const [pageHeadingTree, setPageHeadingTree] = useState(null);
   // eslint-disable-next-line
  const [isCopied, setIsCopied] = useState(false);


  const editorCore = React.useRef(null)

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance
  }, [])

  const handleSave = React.useCallback(async () => {
    const savedData = await editorCore.current.save();
    console.log("Article:"+savedData);
  }, [])



  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    const content_key = "json_content";
    let copyText = "";
    var savedContent = localStorage.getItem(content_key);
        try {
        let content = JSON.parse(savedContent);
        copyText = JSON.stringify(content, null, 2)

    } catch(e) {
        console.log(e) // error in the above string (in this case, yes)!
    }
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {

        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }


    return (
      <>
                <div className="border shadow w-full px-3 py-2   mb-2">
            <button style={{minWidth:"138px"}} className="bg-blue-500 hover:bg-blue-700 text-white text-xs font-semibold  py-2 px-4 rounded-full mr-5 mb-2 mt-2" >
                  Copy as html

            </button>
          </div>
      <div ref = {headingsContainerRef} className={"w-full border shadow "+(props.tall?"h-tall-tool":"h-650-px") }>
      <ReactEditorJS
        onInitialize={handleInitialize}
        tools={EDITOR_JS_TOOLS}
        defaultValue={{
          time: 1635603431943,
          blocks: currentDocContent
        }}
      /> </div>
      </>
    );

}
