/*eslint-disable*/
import React, { useState }from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import StrataEditor from "components/Editor/StrataEditor.js";
import JSONEditor from "components/JsonEditor.js";
import { TOOLS_INDEX } from "tools/ToolsIndex.js"


export default function JsonFormatter() {

  const [isCopied, setIsCopied] = useState(false);
  const [isMiniCopied, setIsMiniCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    const content_key = "json_content";
    let copyText = "";
    var savedContent = localStorage.getItem(content_key);
        try {
        let content = JSON.parse(savedContent);
        copyText = JSON.stringify(content, null, 2)

    } catch(e) {
        console.log(e) // error in the above string (in this case, yes)!
    }
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsMiniCopied(false);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }


    // onClick handler function for the copy button
  const handleCopyMinifiedClick = () => {
    const content_key = "json_content";
    let copyText = "";
    var savedContent = localStorage.getItem(content_key);
        try {
        let content = JSON.parse(savedContent);
        copyText = JSON.stringify(content)

    } catch(e) {
        console.log(e) // error in the above string (in this case, yes)!
    }
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsMiniCopied(true);
        setIsCopied(false);
        setTimeout(() => {
          setIsMiniCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  var tool  = TOOLS_INDEX.json;
  return (
    <>
          <div className="border shadow w-full px-3 py-2   mb-2">
           <button style={{minWidth:"138px"}} className="bg-blue-500 hover:bg-blue-700 text-white text-xs font-semibold  py-2 px-4 rounded-full mr-5 mb-2 mt-2" onClick={handleCopyClick}>
                Copy to clipboard
                          <div
                          className={"transition ease-in-out delay-150 p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800  border shadow border-green-800 absolute top-100 left-200  shadow  z-40 "+(isCopied?"":"hidden")} role="alert">
            <span className="font-medium">Copy successful</span> <br/>
          </div>
          </button>
           <button style={{minWidth:"138px"}} className="transition ease-in-out delay-150 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white border border-blue-500 text-xs py-2 px-4 rounded-full mb-2 mt-2" onClick={handleCopyMinifiedClick}>
                Copy minified
            <div  className={"p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 border shadow border-green-800  absolute top-100  left-200 shadow z-40 "+(isMiniCopied?"":"hidden")} role="alert">
            <span className="font-medium">Copy successful</span><br/>
          </div>
          </button>
          </div>
          <div className="grid grid-cols-3 gap-3 md:display-flex">
              <div  className=" col-span-3 lg:col-span-2 border shadow h-650-px ">
              <JSONEditor/>
              </div>
              <div  className="col-span-3 border  shadow lg:h-150-px lg:col-span-1 sd:h-650-px px-3 py-3 bg-slate-200">
              <h1 className="text-3xl  lg:text-3xl mb-1 mt-1 lg:mb-5 lg:mt-5 capitalize text-blue-800 text-center"> {tool.name}</h1>
              <h2 className="text-base lg:text-xl  text-blue-600 text-center">{tool.description}</h2>
              <p>&nbsp;</p>
              <p className="text-xs lg:text-base text-grey-700 text-justify">{tool.full_description}</p>
              </div>
          </div>
    </>
  )
}
