import React from "react";

export default function Tooltips ({
  message, children
}){
  return (
    <div className="relative flex flex-col items-center group  pointer">
      <div className="absolute top-100 flex flex-col items-center hidden mb-6 group-hover:flex">
       <div className="w-5 h-2 mt-7 rotate-45 bg-white relative top-2 shadow border"></div>
        <span className="relative z-10 p-2 text-xs leading-none  whitespace-no-wrap bg-white shadow rounded-md border w-50-px normal-case">{message}</span>
      </div>
        {children}
    </div>
  );
};
