/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import StrataEditor from "components/Editor/StrataEditor.js";

export default function Index() {
  return (
    <>
      <StrataEditor/>
      </>
  )
}
