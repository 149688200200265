export const TOOLS_INDEX = {
  json: {
      name:"json editor",
      route:"/jsoneditor",
      description:"Allows you to quickly edit format or minify json data.",
      full_description:"Use the Json editor to edit, format, prettify and minify json data.  Json editor support all kind of datatypes like string, number and boolean.",
      enabled:true
  },
  translate:{
      name:"quick translate",
      route:"/translate",
      description:"Quickly translate texts between langauages."
  },
  convertor:{
      name:"quick convert",
      route:"/convertor",
      description:" Quickly convert your data between formats like CSV, TSV and JSON"
  },
  pivot:{
      name:"quick pivot",
      route:"/pivot",
      description:"Pivot your data by converting rows to columns or group by a field"
  },
  base64:{
      name:"base-64",
      route:"/base64",
      description:"Encode/decode base 64 data.  Useful for serialization"
  },
    url:{
      name:"url encoder/decoder",
      route:"/url",
      description:"Encode/decode data from  an url. Quicly extract fields from the url."
  },
      testregex:{
      name:"test regex",
      route:"/regex",
      description:"Test your regex by quickly trying out your regex of test data"
  },
        transcribe:{
      name:"transcribe",
      route:"/transcribe",
      description:"Transcribe your text by dictating to the computer. Simple speech to text"
  },
      audionote:{
      name:"audio note",
      route:"/audio_note",
      description:"Create a quick audio note by recording your speech."
  },
      quicknote:{
      name:"quick note",
      route:"/quicknote",
      description:"Create a text based note quickly.  Take down task/todo or simply use it for quick reference.",
      enabled:true

  },
};
