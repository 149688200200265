   import React, { useCallback, useState, useEffect } from 'react'
    import JSONInput from 'react-json-editor-ajrm';
    import locale    from 'react-json-editor-ajrm/locale/en';
    import COLORS    from './jsoneditortheme.json';

    export default function JSONEditor({id, height, onContentChange }) {
       const content_key = "json_content";
       var savedContent = localStorage.getItem(content_key);

       var savedObject = savedContent!=="undefined"?JSON.parse(savedContent):{ name: "hello world", number:1, boolean:false};
       const [content, setContent] = useState(savedObject);

        useEffect(() => {

            localStorage.setItem(content_key, JSON.stringify(content));
        }, [content]);

       const handleInputChange = useCallback(event => {
            setContent(event.jsObject)
        }, [])
    return(

    <JSONInput
        id          = {id?id:"random_id"}
        placeholder = {content? content:{ name: "hello world", call:1, isenabled:false}}
        theme       = "light_mitsuketa_tribute"
        locale      = { locale }
        height      = {height?height:'100%'}
        width       ={'100%'}
        colors       = {COLORS}
        onChange     = {handleInputChange}
        style        ={{
             body:{fontSize:"14px", paddingTop:"20px", paddingBottom:"20px" }
        }}
    />)
    }
